import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "vuetify/dist/vuetify.min.css";
import Vuetify, {
  VApp, // required
  VBtn,
  VForm,
  VTextField,
  VTextarea,
} from "vuetify/lib";
import "vuetify/src/stylus/app.styl";

Vue.use(Vuetify, {
  theme: { disable: true },
  components: {
    VApp, // required
    VBtn,
    VForm,
    VTextField,
    VTextarea,
  },
});
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

const mountEl = document.querySelector("#app");

new Vue({
  render: (createElement) => {
    const context = {
      props: { ...mountEl.dataset },
    };
    return createElement(App, context);
  },
}).$mount("#app");
