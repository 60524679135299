<template>
  <div class="cform">
    <h1 v-if="verstuurd === false" class="center">{{ title }}</h1>
    <br /><br />
    <v-form v-if="verstuurd === false">
      <div class="inputs center">
        <v-text-field
          v-model="naam"
          label="Naam"
          :rules="[rules.naam]"
          outline
          required
        ></v-text-field
        ><br /><br />
        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          :rules="[rules.email]"
          outline
          required
        ></v-text-field
        ><br /><br />
        <v-textarea
          v-model="bericht"
          counter
          maxlength="1000"
          :rules="[rules.bericht]"
          label="Bericht"
          required
          outline
        ></v-textarea
        ><br /><br />
        <vue-recaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          sitekey="6Lcf3ukSAAAAAK_ia7U2OrLlH4Ld3h1Yyi26oC8d"
        ></vue-recaptcha>
        <p class="recap">
          * Vink aan om te verklaren dat u geen spam verstuurt
        </p>
        <br /><br /><br /><br />
        <div class="text-xs-center">
          <v-btn
            class="white--text"
            :loading="loading"
            :disabled="
              !valid.naam || !valid.email || !valid.bericht || !valid.recaptcha
            "
            color="#1976d2"
            @click="
              loading = true;
              submitForm();
            "
          >
            Verstuur Bericht
          </v-btn>
          <!-- v-btn color="primary" via scoped css anders invloed op pagina waar dit formulier embed is -->
        </div>
      </div> </v-form
    ><br /><br />
    <p v-if="verstuurd" v-html="result" class="contactmsg"></p>
  </div>
</template>

<script>
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "ContactForm",
  props: {
    title: String,
    succes: String,
  },
  components: { VueRecaptcha },
  data: function() {
    return {
      valid: { naam: false, email: false, bericht: false, recaptcha: false },
      naam: "",
      email: "",
      bericht: "",
      verstuurd: false,
      domain: "",
      loading: false,
      result: "",
      rules: {
        naam: (value) => {
          if (value.length > 0) this.valid.naam = true;
          else this.valid.naam = false;
          return !!value || "Verplicht veld.";
        },
        bericht: (value) => {
          if (value.length > 0) this.valid.bericht = true;
          else this.valid.bericht = false;
          return !!value || "Verplicht veld.";
        },
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let etest = pattern.test(value);
          if (etest) {
            this.valid.email = true;
            return true;
          } else if (value.length == 0) {
            this.valid.email = false;
            return "Verplicht veld.";
          } else {
            this.valid.email = false;
            return "Geen correct e-mail adres.";
          }
        },
      },
    };
  },
  created: function() {
    this.domain = String(window.location.hostname).replace(/^www\./, "");
  },
  methods: {
    onCaptchaExpired: function() {
      this.valid.recaptcha = false;
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified: function(recaptchaToken) {
      this.valid.recaptcha = true;
      this.recaptchaToken = recaptchaToken;
    },
    submitForm: async function() {
      let formData = {
        text: this.bericht,
        mailfrom: this.email,
        subject: "Bericht van " + this.naam + " op " + this.domain,
        domain: this.domain,
        recaptchaToken: this.recaptchaToken,
        succes: this.succes,
      };

      const encodeForm = (data) => {
        return Object.keys(data)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&");
      };

      this.result = await axios
        .post(
          "https://europe-west1-brisbane-fc23b.cloudfunctions.net/webApi/contact",
          // "http://localhost:5001/brisbane-fc23b/europe-west1/webApi/contact",
          encodeForm(formData),
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then(function(response) {
          //console.log(response.data.message);
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
      this.verstuurd = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  margin: auto;
  text-align: center;
}
.inputs {
  width: 50%;
}
.recap {
  float: left;
  padding-left: 5px;
  font-size: 70%;
}
/* a {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
} */
</style>
