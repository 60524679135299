<template>
  <div id="app">
    <v-app>
      <ContactForm :title="title" :succes="succes"/>
    </v-app>
  </div>
</template>

<script>
import ContactForm from './components/ContactForm.vue'

export default {
  name: 'app',
  components: {
    ContactForm
  },
  props: {
    title: String,
    succes: String,
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  line-height: 2.0;
}
</style>
